import * as React from 'react'
import { Icon, IconSize } from '../icons/icon'

const onLinkClick = async () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'goto_homepage',
  })
}

const Footer: React.FC = () => (
  <footer className="mx-auto p-sm-fluid bg-black ">
    <div className="grid grid-cols-1 md:grid-cols-3 text-white gap-sm-fluid">
      <div>
        <p className="text-4xl-rfs pb-8 tracking-normal text-white leading-none font-bold font-heading">
          Adresse
        </p>
        <p className="text-2xl-rfs">bekleidung.ch</p>
        <p className="text-2xl-rfs">CORPORATE FASHION</p>
        <p className="text-2xl-rfs">Zielstrasse 38</p>
        <p className="text-2xl-rfs">CH-9050 Appenzell</p>
        <a href="tel:+41717888067" className="text-2xl-rfs">
          +41 71 788 80 67
        </a>
      </div>
      <div>
        <p className="text-base-rfs">
          bekleidung.ch ist ein Geschäftsbereich des Modeunternehmens Goldener Mode AG in Appenzell.
          Seit 93 Jahren (4. Generation) ist es unsere Leidenschaft, die Kundinnen und Kunden in
          Sachen Modetrends zu beraten. Bei bekleidung.ch handhaben wir es gleich: Wir suchen für
          jedes Unternehmen die individuelle Bekleidung, welche das Image und Werte der jeweiligen
          Firma widerspiegeln.
        </p>
      </div>
      <div>
        <div className="pb-4 flex flex-row items-center">
          <a
            className="text-2xl-rfs hover:underline"
            onClick={onLinkClick}
            href="https://www.bekleidung.ch">
            Homepage
          </a>
          <Icon
            size={IconSize.sm}
            name="arrowleft"
            className="ml-2 transform rotate-180 w-6 h-6 mr-4 mr-6"
          />
        </div>
        <div className="pb-4 flex flex-row items-center">
          <a
            onClick={onLinkClick}
            className="text-2xl-rfs hover:underline"
            href="https://www.bekleidung.ch/dienstleistungen/veredelungen/">
            Veredelungsmöglichkeiten
          </a>
          <Icon
            size={IconSize.sm}
            name="arrowleft"
            className="ml-2 transform rotate-180 w-6 h-6 mr-4 mr-6"
          />
        </div>
        <div className="flex flex-row items-center">
          <a
            onClick={onLinkClick}
            className="text-2xl-rfs hover:underline"
            href="https://www.bekleidung.ch/j/shop/terms">
            AGB &amp; Datenschutz
          </a>
          <Icon
            size={IconSize.sm}
            name="arrowleft"
            className="ml-2 transform rotate-180 w-6 h-6 mr-4 mr-6"
          />
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
