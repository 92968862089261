import * as React from 'react'
import { Helmet } from 'react-helmet'
import Footer from './footer'

// Set Theme based on .Env-File
// Removed styles: relative font-text bg-white overflow-hidden

interface LayoutProps {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => (
  <>
    <Helmet>
      <html lang="de" />
    </Helmet>
    <main className="font-text">{children}</main>
    <Footer />
  </>
)
